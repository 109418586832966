.productmaingrid {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 10% !important;
}
.productTypography {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #454238 !important;

  line-height: 29px !important;
  text-align: center !important;
  /* Black - EZBox */

  color: #454238 !important;
}
.productmidgird {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80vh !important;
}

@media only screen and (max-width: 820px) {
  .productTypography {
    font-size: 20px !important;
    padding-top: 10% !important;
  }
  .typographygrid {
    max-width: 60% !important;
  }
  .productmidgird {
    padding-top: 5% !important;
  }
  .footergrid {
    padding-top: 60% !important;
  }

  .footer_list {
    font-size: 16px !important;
  }
  .footer_typography {
    font-size: 16px !important;
  }
  .footer_ezbox {
    font-size: 16px !important;
  }
  .footer_typography {
    font-size: 16px !important;
  }
  .Footer_typo {
    font-size: 16px !important;
  }
  .footersvg {
    padding-left: 6% !important;
  }
  .footer_grid {
    padding-top: 130% !important;
  }
}
@media only screen and (max-width: 600px) {
  .productTypography {
    font-size: 16px !important;
    padding-top: 15% !important;
  }
  .footer_grid {
    padding-top: 120% !important;
  }
}

#navbar {
  /* background: linear-gradient(
    180deg,
    #ffffff 27.08%,
    rgba(255, 255, 255, 0.15) 100%
  ) !important; */
  background: #ffffff !important;
  position: fixed !important;
  top: 0 !important;
  height: 94px !important;
  line-height: 100px !important;
  width: 100vw !important;
  z-index: 10 !important;
  display: flex !important;
}
.nav-wrapper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  /* margin: auto !important; */
  margin: 0 auto 0 auto !important;
  text-align: center !important;
  width: 80% !important;
}
.logo {
  height: 50px !important;
}

#navbar ul {
  display: flex !important;
  float: right !important;
  list-style: none !important;
  /* margin-right: 14px; */
  margin-top: -2px;
  text-align: right;
  transition: transform 0.5s ease-out;
  -webkit-transition: transform 0.5s ease-out;
}
#navbar li {
  display: inline-block !important;
}
.background {
  background: linear-gradient(
    180deg,
    #3399fe 27.08%,
    rgba(51, 153, 254, 0.14) 100%
  ) !important;
  color: #fcfcfc !important;
}
#navbar li a {
  /* color: rgb(13, 26, 38); */
  display: block !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #454238;

  /* height: 50px !important; */
  letter-spacing: 1px !important;
  margin: 0 20px !important;
  padding: 0 3px !important;
  position: relative !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
  transition: all 0.5s ease !important;
  -webkit-transition: all 0.5s ease !important;
}

#navbar ul li a:hover {
  background: linear-gradient(
    180deg,
    #3399fe 27.08%,
    rgba(51, 153, 254, 0.14) 100%
  );
}

/* .active {
  background: linear-gradient(
    180deg,
    #3399fe 27.08%,
    rgba(51, 153, 254, 0.14) 100%
  ) !important;
} */
.home-logo {
  padding-left: 25%;
}
.Home_typo {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 30px !important;
  color: #454238 !important;

  line-height: 43px;
  width: 64% !important;
  text-align: left;
  /* padding-top: 15%; */

  padding-right: 7% !important;
}
.Home_img {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-top: 11% !important;
}
.frame {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f7a119 0%,
    rgba(247, 161, 25, 0) 100%
  ) !important;
  width: 80% !important;
  height: 100%;
  object-fit: cover !important;
}
.Down_Arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.Typo_Grid {
  /* align-items: center; */
  padding-top: 13% !important;
}
.css-ahj2mt-MuiTypography-root {
  font-family: Urbanist;
  letter-spacing: normal;
}

.mobilenav {
  display: flex !important;
  flex-direction: column !important;
}
.mobilenav a {
  text-decoration: none !important;
  font-size: 20px !important;

  padding: 10px !important;
  color: #ffffff;
}
.Clientreviewsheading {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 58px !important;
  /* identical to box height */

  text-align: center !important;
  /* Blue - EZBox */

  color: #3399fe;
}
.cleintreviewsheadingdiv {
  padding-top: 15% !important;
}
.downarrowsvg {
  cursor: pointer !important;
  margin-top: 3% !important;
}
.ezbox_img {
  width: 70%;
  object-fit: cover !important;
  height: 520px !important;
}

@media only screen and (max-width: 2400px) {
  .hamburgermenu {
    display: none !important;
  }
}

@media only screen and (max-width: 1550px) {
  #navbar li a {
    margin: 0 11px !important;
    font-size: 16px;
  }
  .downarrowsvg {
    top: 116% !important;
  }
}
@media only screen and (max-width: 1390px) {
  #navbar li a {
    margin: 0 6px !important;
    font-size: 16px;
  }
}
@media only screen and (max-width: 1300px) {
  #navbar ul {
    display: none !important;
  }
  .hamburgermenu {
    display: block !important;
  }
}
@media only screen and (max-width: 820px) {
  .Typo_Grid {
    padding-top: 20% !important;
  }
  .Home_typo {
    font-size: 20px !important;
    padding-right: 0% !important;
  }
  .frame {
    width: 90% !important;
  }
  .Home_img {
    display: flex !important;
    /* justify-content: center !important; */
    /* justify-content: flex-end !important; */
    padding-top: 2% !important;
  }
  .ezboxx {
    padding-left: 11% !important;
  }
}
@media only screen and (max-width: 600px) {
  .ezboxx {
    display: flex !important;
    /* justify-content: center !important; */
    justify-content: flex-end !important;
    align-items: baseline !important;
  }
  .ytplayer {
    padding-top: 10% !important;
  }
  .ezbox_logo {
    display: flex !important;
    justify-content: CENTER !important;
    margin-left: 31% !important;
  }
  .ezbox_img {
    width: 100% !important;
    height: 267px !important;
  }
  .mobilenav a {
    font-size: 16px !important;
  }
  .homeezbox {
    width: 70% !important;
  }
  .advertisingdiv {
    padding-left: 0px !important;
  }
  .questionmark {
    padding-top: 2% !important;
    width: 20% !important;
  }
}
@media only screen and (max-width: 430px) {
  .ezbox_logo {
    display: flex !important;
    justify-content: CENTER !important;
    margin-left: 0% !important;
  }
  .ezbox_img {
    width: 80% !important;
  }
  .Typo_Grid {
    padding-top: 25% !important;
  }
}

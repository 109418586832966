.footer_grid1 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #fcfcfc !important;
}

.Footer_typo {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: #454238 !important;

  line-height: 24px !important;
}
.footer_typography {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  margin-right: 1% !important;
  color: #454238 !important;
}
.footer_ezbox {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #3399fe;
}
.footer_list {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  color: #454238 !important;

  line-height: 24px !important;
  margin-bottom: 3% !important;
  text-decoration: none !important;
}
.footer_div {
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: center !important; */
}
@media only screen and (max-width: 820px) {
  .footer_div {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 4% !important;
  }
  .followus {
    display: none !important;
  }
  .ezimg {
    width: 50% !important;
  }
  /* .footergrid {
    display: flex !important;
  } */
}

.Consultingtypo {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #454238 !important;

  line-height: 38px !important;
  /* padding-right: 19% !important; */
}
.Consultingtypogrid {
  /* padding-top: 8% !important; */
  /* align-items: center !important; */
  /* padding-bottom: 25% !important; */
  padding-top: 15% !important;
  /* padding-right: 10% !important; */
  padding-left: 10% !important;
}
.Consultingimgdiv {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 34% !important;
  padding-left: 31% !important;
}
.buttondiv {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  justify-content: center !important;
  align-items: flex-end !important;
  padding-top: 40% !important;

  width: 53%;
  /* background-color: red; */
  float: right;
  /* height: 78vh; */
}
.buttontypo {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  /* identical to box height */
  margin-bottom: 8% !important;
  margin-top: 8% !important;
  text-align: right !important;
  background: linear-gradient(
    180deg,
    #3399fe 27.08%,
    rgba(51, 153, 254, 0.14) 100%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.bb {
  border-right: 10px solid #3399fe !important;
  border-radius: 5px !important;
  padding-right: 3%;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  justify-content: center !important;
}
.consultingimg {
  width: 98% !important;
}

@media only screen and (max-width: 1550px) {
  .Consultingtypo {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 26px !important;
    line-height: 38px !important;
    /* padding-right: 19% !important; */
  }
}
@media only screen and (max-width: 1300px) {
  .Consultingtypo {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 38px !important;
    /* padding-right: 19% !important; */
  }
}

@media only screen and (max-width: 1100px) {
  .Consultingtypogrid {
    padding-right: 2% !important;
  }
}

@media only screen and (max-width: 900px) {
  .Consultingimgdiv {
    padding-left: 0% !important;
  }
  .Consultingtypo {
    padding: 0 13% !important;
  }
  .buttondiv {
    padding-top: 10% !important;
  }
}

@media only screen and (max-width: 900px) {
  .consultingimg {
    width: 100% !important;
  }
}
@media only screen and (max-width: 600px) {
  .Consultingtypo {
    font-size: 16px !important;
  }
}

.form {
  padding-top: 20% !important;
  height: 100% !important;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* border-left: 10px solid #3399fe !important; */
}
.contactform {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
  /* margin-bottom: 10% !important; */
}
.borderimg {
  width: 2% !important;
  height: 75% !important;
  position: absolute !important;
  /* right: 13%; */
  left: 9% !important;
  margin-bottom: 10% !important;
}

.inputfield {
  width: 50% !important;
  height: 60px !important;
  margin-bottom: 5% !important;
  border: none !important;
  /* box-shadow: 2px 2px 2px 2px #3399fe !important; */
  filter: drop-shadow(0px 4px 17px rgba(51, 153, 254, 0.4)) !important;
  color: #454238 !important;
  font-size: 24px !important;
  padding-left: 3% !important;
  border-radius: 10px !important;
}
/* .inputfield:focus {
  background-color: white !important;
  border-color: #c4c4c4 !important;
} */

input::placeholder {
  color: #c4c4c4 !important;
}
.contactsvg {
  width: 90%;
}
.textarea {
  border: none !important;
  /* box-shadow: 2px 2px 2px 2px #3399fe !important; */
  filter: drop-shadow(0px 4px 17px rgba(51, 153, 254, 0.4)) !important;

  color: #454238 !important;
  font-size: 24px !important;
  padding-left: 3% !important;
  width: 50% !important;
  height: 222px !important;
  border-radius: 10px !important;
  padding-top: 2% !important;
  margin-bottom: -45px !important;
}
textarea::placeholder {
  color: #c4c4c4 !important;
}
@media only screen and (max-width: 900px) {
  .textarea {
    width: 80% !important;
  }
  .inputfield {
    width: 80% !important;
  }
  .contactsvg {
    width: 100% !important;
  }
  .borderimg {
    height: 61% !important;
    left: 5% !important;
  }
  .contactbtn {
    margin-right: 5% !important;
  }
}

@media only screen and (max-width: 600px) {
  .form {
    padding-top: 30% !important;
  }
  .textarea {
    width: 80% !important;
    font-size: 16px !important;
    margin-bottom: 0% !important;
  }
  .inputfield {
    width: 80% !important;
    font-size: 16px !important;
  }
  .contactsvg {
    width: 100% !important;
    padding-top: 5% !important;
  }
  .borderimg {
    height: 69% !important;
    left: 4% !important;
  }
  .Contactusbutton {
    margin-top: 5% !important;
  }
}

.Advertising_heading {
  display: flex !important;
  justify-content: center !important;
  font-weight: 600 !important;
  font-size: 50px !important;
  color: #454238 !important;

  line-height: 115px !important;
  text-transform: uppercase !important;
  padding-left: 24% !important;
  padding-top: 11% !important;
}
.upper_left_div {
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: center !important; */
  align-items: center !important;
  padding-top: 6% !important;
}
.Advertising_para {
  align-items: center !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #454238 !important;

  line-height: 43px !important;
  /* width: 80% !important; */
  padding-top: 20% !important;
  padding-right: 19% !important;
  word-break: break-all !important;
  padding-left: 17% !important;
}
.upper_right_div {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
  padding-top: 3% !important;
  padding-bottom: 12% !important;
}
.Advertising-heading {
  border-left: 10px solid #3399fe !important;
  border-radius: 4px !important;
  color: #454238 !important;
  font-size: 58px !important;
  font-weight: 600 !important;
  padding-left: 4% !important;
  margin-left: 18% !important;
  text-transform: uppercase !important;
}
.emptdiv {
  width: 2px !important;
  height: 100px !important;
  color: red !important;
}
.Contactusbutton {
  padding: 10px !important;
  width: 170px !important;
  text-transform: capitalize !important;
  font-family: Urbanist !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  /* line-height: 43px !important; */

  color: #ffffff !important;
  background: #3399fe !important;
  border-radius: 10px !important;
}
.backimg {
  /* display: flex !important; */
  /* justify-content: center; */
  /* align-items: center !important;
  height: 100vh !important;
  width: 100% !important; */
  /* transform: scale(0.5) !important; */
  /* background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important; */

  background-image: url("../../assets/images/AdvestisingSvg.svg");
  display: flex !important;
  align-items: center !important;
  height: 1500px !important;
  /* width: 1px !important; */
  width: 99vw !important;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.bottom_left_div {
  display: flex !important;
  flex-direction: column !important;
  /* justify-content: center !important; */
  align-items: center !important;
  padding-bottom: 35% !important;
  /* padding-left: 9% !important; */
}
.advertisingimg {
  overflow: hidden !important;
  width: 98% !important;
}

@media only screen and (max-width: 1700px) {
  .advertisingimg {
    width: 98% !important;
  }
}
@media only screen and (max-width: 1300px) {
  .Advertising_para {
    align-items: center !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 43px !important;
    margin-left: 2% !important;
    /* width: 80% !important; */
    /* padding-top: 20% !important; */
    /* padding-right: 19% !important; */
    word-break: break-all !important;
    padding: 8% !important;
  }
  .Advertising_heading {
    display: flex !important;
    justify-content: center !important;
    font-weight: 600 !important;
    font-size: 55px !important;
    line-height: 115px !important;
    text-transform: uppercase !important;
    padding-top: 11% !important;
    padding-left: 0% !important;
    padding: 0%;
  }
  .advertisingdiv {
    display: flex !important;
    justify-content: center !important;
  }
  .advertisingimg {
    width: 80% !important;
  }
  .bottom_left_div {
    padding-bottom: 50% !important;
  }
  .Advertising-heading {
    font-size: 67px !important;
  }
  .backimg {
    height: 1050px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .Advertising_heading {
    padding-left: 11% !important;
  }
}
@media only screen and (max-width: 820px) {
  .Advertising_para {
    align-items: center !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 43px !important;
    margin-left: 2% !important;
    /* width: 80% !important; */
    /* padding-top: 20% !important; */
    /* padding-right: 19% !important; */
    word-break: break-all !important;
    padding: 8% !important;
  }
  .Advertising_heading {
    display: flex !important;
    justify-content: center !important;
    font-weight: 600 !important;
    font-size: 55px !important;
    line-height: 115px !important;
    text-transform: uppercase !important;
    padding-top: 11% !important;
    padding-left: 0% !important;
    padding: 0%;
  }
  .advertisingdiv {
    display: flex !important;
    justify-content: center !important;
  }
  .advertisingimg {
    width: 80% !important;
  }
  .bottom_left_div {
    padding-bottom: 50% !important;
  }
  .Advertising-heading {
    font-size: 67px !important;
  }
  .backimg {
    height: 1050px !important;
  }
}
@media only screen and (max-width: 415px) {
  .Advertising_heading {
    font-size: 30px !important;
  }
  .Advertising_para {
    font-size: 16px !important;
  }
  .Advertising-heading {
    font-size: 30px !important;
  }
  .backimg {
    height: 700px !important;
  }
  .advertisingbtn {
    padding-right: 50% !important;
  }
}

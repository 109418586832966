.ProductHeading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 43px !important;
  padding-bottom: 3% !important;

  /* Blue - EZBox */

  color: #3399fe !important;
}
.ProductDescription {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;

  /* Black - EZBox */

  color: #454238 !important;
}
/* .bgimg {
  display: flex !important;
  flex-direction: column !important;
  background-image: url("../../assets//images/Vector.svg");
} */
.productlinesvg {
  position: absolute;
  top: 140%;
  /* width: 100vw !important; */
}
.bottomgrid {
  display: flex;
  padding-bottom: 25%;
}
.linesvg {
  overflow: hidden !important;
}
@media only screen and (max-width: 1550px) {
  .linesvg {
    /* width: 1540px !important; */
  }
}
@media only screen and (max-width: 1256px) {
  .linesvg {
    /* width: 1260px !important; */
  }
}
@media only screen and (max-width: 900px) {
  .bottomgrid {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 820px) {
  .bottomgrid {
    display: flex !important;
    flex-direction: column !important;
  }
  .linesvg {
    /* width: 825px !important; */
  }
  .imggrid {
    display: flex !important;
    justify-content: center !important;
  }
  .ProductDescription {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 600px) {
  .productlinesvg {
    /* display: none !important; */
  }
  .ProductDescription {
    font-size: 16px !important;
  }
  .ProductHeading {
    font-size: 20px !important;
  }
  .productlinemaingrid {
    padding-top: 15% !important;
  }
}

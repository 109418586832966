.clientreview {
  background: #fcfcfc !important;

  box-shadow: 0px 7px 20px rgba(124, 167, 250, 0.4);
  border-radius: 20px !important;
  width: 300px !important;
  height: 300px !important;
  padding: 5% 15% 15% 15% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 8% !important;
}
.clientreview:hover {
  z-index: 2 !important;
}
.customerimg {
  padding-top: 12% !important;
  padding-bottom: 8% !important;
  width: 80px;
  height: 115px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}
.customername {
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #454238 !important;

  line-height: 29px !important;
  /* identical to box height */
  text-align: center !important;
  margin-bottom: 4% !important;
}
.customerdescription {
  font-size: 14px !important;
  color: #454238 !important;

  line-height: 17px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  padding-top: 20% !important;
  height: 70px !important;
}
.carouselimg {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-end !important;
}
/* .Customerreviewcaruseldiv1 {
  padding-left: 3% !important;
} */

@media only screen and (max-width: 1550px) {
  .clientreview {
    width: 255px !important;
  }
  .Customerreviewcaruseldiv1 {
    padding-left: 0% !important;
  }
}
@media only screen and (max-width: 900px) {
  .Customerreviewcaruseldiv1 {
    padding-left: 8% !important;
  }
}
@media only screen and (max-width: 640px) {
  .Customerreviewcaruseldiv1 {
    padding-left: 20% !important;
  }
}
@media only screen and (max-width: 493px) {
  .Customerreviewcaruseldiv1 {
    padding-left: 17% !important;
  }
}
@media only screen and (max-width: 412px) {
  .Customerreviewcaruseldiv1 {
    padding-left: 13% !important;
  }
  .Clientreviewsheading {
    font-size: 25px !important;
  }
}
